import React from "react"

import Seo from "../components/seo"
import OpenSection from "../components/layouts/open-section"
import costanti from "../properties/costanti"
import Svg from "../components/ui/svg"
import Services from "../components/homepage/services"

export default function Products(){

    return(
        <>
            <Seo title="I nostri Servizi" description="Supporto e assistenza per lavorare in tranquillità, rendere sicuri e semplici i processi produttivi e far crescere il tuo business" />

            <div className={"w-full h-auto flex flex-col justify-center items-center text-justify text-xl"}>
                <div className={`group w-full flex flex-col items-center justify-center 
                text-secondary-600 dark:text-secondary-100 `+ costanti.theme.color.img.inverted}>
                    <OpenSection
                    leftText="SISTEMI EFFICIENTI SEMPRE!"
                    leftSubChild=
                    {
                        <p className="text-white font-bold pt-5 portrait:pb-10 text-base">
                            Supporto e assistenza  per lavorare in tranquillità  rendere sicuri e fluidi (semplici) 
                            i processi produttivi e far crescere il tuo business
                        </p>
                    }
                    rightChild=
                    {
                        <Svg.Done className="group-hover:scale-110 group-hover:-hue-rotate-30 duration-500"/>
                    }
                    darkbg />
                </div>
            </div>

            <div className={"relative w-full overflow-x-hidden overflow-y-auto -mt-16 md:-mt-28 flex " +
            "flex-col justify-center items-center pb-20"} >
                {/* <p className={"bg-white px-10 py-20 text-6xl text-center w-3/5 rounded-3xl mb-14 "+
                "text-emerald-600 font-bold border-1 border-secondary-600"}>
                    Come possiamo aiutarti?
                </p> */}
                <Services noIntro />
            </div>

            <div data-sal="zoom-in" data-sal-duration="700"
            className="group flex w-full items-center justify-center mt-10 md:mt-20">
                <Svg.Server_Up className="w-2/3 group-hover:-hue-rotate-30 duration-700"/>
            </div>

        </>
    )
}